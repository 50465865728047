import { default as agentsR0JNhvlpz3Meta } from "/vercel/path1/pages/admin/agency-management/[agencyID]/agents.vue?macro=true";
import { default as indexJNvIlsj0f1Meta } from "/vercel/path1/pages/admin/agency-management/[agencyID]/index.vue?macro=true";
import { default as _91agencyID_93xXn8VB6DhEMeta } from "/vercel/path1/pages/admin/agency-management/[agencyID].vue?macro=true";
import { default as indexaEup7hZKEsMeta } from "/vercel/path1/pages/admin/agency-management/index.vue?macro=true";
import { default as mutationsTyMNYQ4O5AMeta } from "/vercel/path1/pages/admin/agency-management/mutations.js?macro=true";
import { default as queriesHEywxCVL4lMeta } from "/vercel/path1/pages/admin/agency-management/queries.js?macro=true";
import { default as admin4RaJGS4LTZMeta } from "/vercel/path1/pages/admin.vue?macro=true";
import { default as authenticateEQDX3yONJFMeta } from "/vercel/path1/pages/authenticate.vue?macro=true";
import { default as eligible_45quotesojKzolFuAyMeta } from "/vercel/path1/pages/eligible-quotes.vue?macro=true";
import { default as indexJ7d6BfZ2vfMeta } from "/vercel/path1/pages/non-renewals/index.vue?macro=true";
import { default as queriesceuKTTyZj1Meta } from "/vercel/path1/pages/non-renewals/queries.js?macro=true";
import { default as pinguATbf1KJ9FMeta } from "/vercel/path1/pages/ping.vue?macro=true";
import { default as mutationsAU8dzzcWSNMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/mutations.js?macro=true";
import { default as queriesZAVqeQFWbfMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/queries.js?macro=true";
import { default as reviewg7b80jytSkMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/review.vue?macro=true";
import { default as update2E6og3zUe1Meta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/update.vue?macro=true";
import { default as agentOfRecordYlnKY4QwuXMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord.vue?macro=true";
import { default as cancellationContextlvZzxAH8RSMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation/cancellationContext.js?macro=true";
import { default as index3cIVfKwk2mMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation/index.vue?macro=true";
import { default as reviewWSdyAcSmOIMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation/review.vue?macro=true";
import { default as cancellationYsqHMa69P4Meta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation.vue?macro=true";
import { default as billingwnf8kXLpaLMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/billing.vue?macro=true";
import { default as mortgagee0xy2JsaZYNMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/mortgagee.vue?macro=true";
import { default as mutationsdhkCvBB6JlMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/mutations.js?macro=true";
import { default as partiesIM3C33wVdFMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/parties.vue?macro=true";
import { default as partiesStateFactory1075NKa2DOMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/partiesStateFactory.js?macro=true";
import { default as queriesM0NKHxmJldMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/queries.js?macro=true";
import { default as reviewCo1LgC3tSWMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/review.vue?macro=true";
import { default as changejXU0ApkCynMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change.vue?macro=true";
import { default as mutationsa6VEyeHyKEMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/mutations.js?macro=true";
import { default as queriescaafJWiHiEMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/queries.js?macro=true";
import { default as effectiveDateniG0r4oC1AMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/effectiveDate.vue?macro=true";
import { default as mutations3CCd2VDGl1Meta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/mutations.js?macro=true";
import { default as queries1nAzfGeozNMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/queries.js?macro=true";
import { default as reviewl1rmyqXV1nMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/review.vue?macro=true";
import { default as reschedule8v65xlUSBUMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule.vue?macro=true";
import { default as contactsIeHvtY173LMeta } from "/vercel/path1/pages/policy/[policyNumber]/file-claim/contacts.vue?macro=true";
import { default as createClaimPayload1WXsKQbmWEMeta } from "/vercel/path1/pages/policy/[policyNumber]/file-claim/createClaimPayload.js?macro=true";
import { default as damagehz8bhfCV8bMeta } from "/vercel/path1/pages/policy/[policyNumber]/file-claim/damage.vue?macro=true";
import { default as reviewk0mU5C66kCMeta } from "/vercel/path1/pages/policy/[policyNumber]/file-claim/review.vue?macro=true";
import { default as file_45claimY9a74dauqFMeta } from "/vercel/path1/pages/policy/[policyNumber]/file-claim.vue?macro=true";
import { default as activityEfWYhTbCz8Meta } from "/vercel/path1/pages/policy/[policyNumber]/index/activity.vue?macro=true";
import { default as claimsF9RrccaNJfMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/claims.vue?macro=true";
import { default as contactbwwc1OPSw0Meta } from "/vercel/path1/pages/policy/[policyNumber]/index/contact.vue?macro=true";
import { default as documentsN4keVHWXwlMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/documents.vue?macro=true";
import { default as indexhidKqG2U6xMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/index.vue?macro=true";
import { default as mutationsZ4lH5tmc8KMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/mutations.js?macro=true";
import { default as notesdv4TdbMsFqMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/notes.vue?macro=true";
import { default as queriesyIj1Hd2GKcMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/queries.js?macro=true";
import { default as renewal_45comparisonwcbTSGVmRSMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/renewal-comparison.vue?macro=true";
import { default as index79GEQtc70PMeta } from "/vercel/path1/pages/policy/[policyNumber]/index.vue?macro=true";
import { default as _91policyNumber_93IPiHThzZsCMeta } from "/vercel/path1/pages/policy/[policyNumber].vue?macro=true";
import { default as indexTj4RXnma2JMeta } from "/vercel/path1/pages/search/index.vue?macro=true";
import { default as queriesJH0jcTi8YMMeta } from "/vercel/path1/pages/search/queries.js?macro=true";
import { default as agency_45detailsh11mvzPEXYMeta } from "/vercel/path1/pages/settings/index/agency-details.vue?macro=true";
import { default as commissions6stSji7zCuMeta } from "/vercel/path1/pages/settings/index/commissions.vue?macro=true";
import { default as index4r0iIvQZYgMeta } from "/vercel/path1/pages/settings/index/index.vue?macro=true";
import { default as integrationsUm1aIcQSTHMeta } from "/vercel/path1/pages/settings/index/integrations.vue?macro=true";
import { default as mutationsC0AXSqD2UcMeta } from "/vercel/path1/pages/settings/index/mutations.js?macro=true";
import { default as queriesQU37PJeK0mMeta } from "/vercel/path1/pages/settings/index/queries.js?macro=true";
import { default as _91userID_93kH02LVmxhKMeta } from "/vercel/path1/pages/settings/index/team/[userID].vue?macro=true";
import { default as indexxH3MYhsmsuMeta } from "/vercel/path1/pages/settings/index/team/index.vue?macro=true";
import { default as teamNlU4fkX8EUMeta } from "/vercel/path1/pages/settings/index/team.vue?macro=true";
import { default as indexRDl0kZWQMpMeta } from "/vercel/path1/pages/settings/index.vue?macro=true";
import { default as indexVz5EpmTqq2Meta } from "/vercel/path1/pages/upcoming-renewals/index.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: admin4RaJGS4LTZMeta || {},
    component: () => import("/vercel/path1/pages/admin.vue"),
    children: [
  {
    name: _91agencyID_93xXn8VB6DhEMeta?.name,
    path: "agency-management/:agencyID()",
    component: () => import("/vercel/path1/pages/admin/agency-management/[agencyID].vue"),
    children: [
  {
    name: "admin-agency-management-agencyID-agents",
    path: "agents",
    component: () => import("/vercel/path1/pages/admin/agency-management/[agencyID]/agents.vue")
  },
  {
    name: "admin-agency-management-agencyID",
    path: "",
    component: () => import("/vercel/path1/pages/admin/agency-management/[agencyID]/index.vue")
  }
]
  },
  {
    name: "admin-agency-management",
    path: "agency-management",
    component: () => import("/vercel/path1/pages/admin/agency-management/index.vue")
  },
  {
    name: "admin-agency-management-mutations",
    path: "agency-management/mutations",
    component: () => import("/vercel/path1/pages/admin/agency-management/mutations.js")
  },
  {
    name: "admin-agency-management-queries",
    path: "agency-management/queries",
    component: () => import("/vercel/path1/pages/admin/agency-management/queries.js")
  }
]
  },
  {
    name: "authenticate",
    path: "/authenticate",
    component: () => import("/vercel/path1/pages/authenticate.vue")
  },
  {
    name: "eligible-quotes",
    path: "/eligible-quotes",
    meta: eligible_45quotesojKzolFuAyMeta || {},
    component: () => import("/vercel/path1/pages/eligible-quotes.vue")
  },
  {
    name: "non-renewals",
    path: "/non-renewals",
    meta: indexJ7d6BfZ2vfMeta || {},
    component: () => import("/vercel/path1/pages/non-renewals/index.vue")
  },
  {
    name: "non-renewals-queries",
    path: "/non-renewals/queries",
    component: () => import("/vercel/path1/pages/non-renewals/queries.js")
  },
  {
    name: "ping",
    path: "/ping",
    component: () => import("/vercel/path1/pages/ping.vue")
  },
  {
    name: _91policyNumber_93IPiHThzZsCMeta?.name,
    path: "/policy/:policyNumber()",
    component: () => import("/vercel/path1/pages/policy/[policyNumber].vue"),
    children: [
  {
    name: "policy-policyNumber-termID-transactionID-agentOfRecord",
    path: ":termID()/:transactionID()/agentOfRecord",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord.vue"),
    children: [
  {
    name: "policy-policyNumber-termID-transactionID-agentOfRecord-mutations",
    path: "mutations",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/mutations.js")
  },
  {
    name: "policy-policyNumber-termID-transactionID-agentOfRecord-queries",
    path: "queries",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/queries.js")
  },
  {
    name: "policy-policyNumber-termID-transactionID-agentOfRecord-review",
    path: "review",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/review.vue")
  },
  {
    name: "policy-policyNumber-termID-transactionID-agentOfRecord-update",
    path: "update",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/update.vue")
  }
]
  },
  {
    name: cancellationYsqHMa69P4Meta?.name,
    path: ":termID()/:transactionID()/cancellation",
    meta: cancellationYsqHMa69P4Meta || {},
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation.vue"),
    children: [
  {
    name: "policy-policyNumber-termID-transactionID-cancellation-cancellationContext",
    path: "cancellationContext",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation/cancellationContext.js")
  },
  {
    name: "policy-policyNumber-termID-transactionID-cancellation",
    path: "",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation/index.vue")
  },
  {
    name: "policy-policyNumber-termID-transactionID-cancellation-review",
    path: "review",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation/review.vue")
  }
]
  },
  {
    name: "policy-policyNumber-termID-transactionID-change",
    path: ":termID()/:transactionID()/change",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change.vue"),
    children: [
  {
    name: "policy-policyNumber-termID-transactionID-change-billing",
    path: "billing",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/billing.vue")
  },
  {
    name: "policy-policyNumber-termID-transactionID-change-mortgagee",
    path: "mortgagee",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/mortgagee.vue")
  },
  {
    name: "policy-policyNumber-termID-transactionID-change-mutations",
    path: "mutations",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/mutations.js")
  },
  {
    name: "policy-policyNumber-termID-transactionID-change-parties",
    path: "parties",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/parties.vue")
  },
  {
    name: "policy-policyNumber-termID-transactionID-change-partiesStateFactory",
    path: "partiesStateFactory",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/partiesStateFactory.js")
  },
  {
    name: "policy-policyNumber-termID-transactionID-change-queries",
    path: "queries",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/queries.js")
  },
  {
    name: "policy-policyNumber-termID-transactionID-change-review",
    path: "review",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/review.vue")
  }
]
  },
  {
    name: "policy-policyNumber-termID-transactionID-mutations",
    path: ":termID()/:transactionID()/mutations",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/mutations.js")
  },
  {
    name: "policy-policyNumber-termID-transactionID-queries",
    path: ":termID()/:transactionID()/queries",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/queries.js")
  },
  {
    name: "policy-policyNumber-termID-transactionID-reschedule",
    path: ":termID()/:transactionID()/reschedule",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule.vue"),
    children: [
  {
    name: "policy-policyNumber-termID-transactionID-reschedule-effectiveDate",
    path: "effectiveDate",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/effectiveDate.vue")
  },
  {
    name: "policy-policyNumber-termID-transactionID-reschedule-mutations",
    path: "mutations",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/mutations.js")
  },
  {
    name: "policy-policyNumber-termID-transactionID-reschedule-queries",
    path: "queries",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/queries.js")
  },
  {
    name: "policy-policyNumber-termID-transactionID-reschedule-review",
    path: "review",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/review.vue")
  }
]
  },
  {
    name: "policy-policyNumber-file-claim",
    path: "file-claim",
    meta: file_45claimY9a74dauqFMeta || {},
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/file-claim.vue"),
    children: [
  {
    name: "policy-policyNumber-file-claim-contacts",
    path: "contacts",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/file-claim/contacts.vue")
  },
  {
    name: "policy-policyNumber-file-claim-createClaimPayload",
    path: "createClaimPayload",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/file-claim/createClaimPayload.js")
  },
  {
    name: "policy-policyNumber-file-claim-damage",
    path: "damage",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/file-claim/damage.vue")
  },
  {
    name: "policy-policyNumber-file-claim-review",
    path: "review",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/file-claim/review.vue")
  }
]
  },
  {
    name: index79GEQtc70PMeta?.name,
    path: "",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index.vue"),
    children: [
  {
    name: "policy-policyNumber-index-activity",
    path: "activity",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/activity.vue")
  },
  {
    name: "policy-policyNumber-index-claims",
    path: "claims",
    meta: claimsF9RrccaNJfMeta || {},
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/claims.vue")
  },
  {
    name: "policy-policyNumber-index-contact",
    path: "contact",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/contact.vue")
  },
  {
    name: "policy-policyNumber-index-documents",
    path: "documents",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/documents.vue")
  },
  {
    name: "policy-policyNumber-index",
    path: "",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/index.vue")
  },
  {
    name: "policy-policyNumber-index-mutations",
    path: "mutations",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/mutations.js")
  },
  {
    name: "policy-policyNumber-index-notes",
    path: "notes",
    meta: notesdv4TdbMsFqMeta || {},
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/notes.vue")
  },
  {
    name: "policy-policyNumber-index-queries",
    path: "queries",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/queries.js")
  },
  {
    name: "policy-policyNumber-index-renewal-comparison",
    path: "renewal-comparison",
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/renewal-comparison.vue")
  }
]
  }
]
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/vercel/path1/pages/search/index.vue")
  },
  {
    name: "search-queries",
    path: "/search/queries",
    component: () => import("/vercel/path1/pages/search/queries.js")
  },
  {
    name: indexRDl0kZWQMpMeta?.name,
    path: "/settings",
    component: () => import("/vercel/path1/pages/settings/index.vue"),
    children: [
  {
    name: "settings-index-agency-details",
    path: "agency-details",
    component: () => import("/vercel/path1/pages/settings/index/agency-details.vue")
  },
  {
    name: "settings-index-commissions",
    path: "commissions",
    meta: commissions6stSji7zCuMeta || {},
    component: () => import("/vercel/path1/pages/settings/index/commissions.vue")
  },
  {
    name: "settings-index",
    path: "",
    component: () => import("/vercel/path1/pages/settings/index/index.vue")
  },
  {
    name: "settings-index-integrations",
    path: "integrations",
    component: () => import("/vercel/path1/pages/settings/index/integrations.vue")
  },
  {
    name: "settings-index-mutations",
    path: "mutations",
    component: () => import("/vercel/path1/pages/settings/index/mutations.js")
  },
  {
    name: "settings-index-queries",
    path: "queries",
    component: () => import("/vercel/path1/pages/settings/index/queries.js")
  },
  {
    name: teamNlU4fkX8EUMeta?.name,
    path: "team",
    component: () => import("/vercel/path1/pages/settings/index/team.vue"),
    children: [
  {
    name: "settings-index-team-userID",
    path: ":userID()",
    component: () => import("/vercel/path1/pages/settings/index/team/[userID].vue")
  },
  {
    name: "settings-index-team",
    path: "",
    component: () => import("/vercel/path1/pages/settings/index/team/index.vue")
  }
]
  }
]
  },
  {
    name: "upcoming-renewals",
    path: "/upcoming-renewals",
    meta: indexVz5EpmTqq2Meta || {},
    component: () => import("/vercel/path1/pages/upcoming-renewals/index.vue")
  }
]